import { Box } from '@stars-ecom/shared-atoms-ui'
import { StoreLocatorLayout } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter, siteFormatter } from '../utils/metaTitleFormatter'

const StoreLocatorPage = (props) => {
  const { pageContext, location } = props
  const { storeLocator } = pageContext

  return (
    <Box w="full" style={{ backgroundColor: '#f6f3f1' }}>
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>
          {`${lengthLimiter(storeLocator?.title)} - ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )} `}
        </title>
        <meta
          name="description"
          content={`Découvrez les points de vente ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )}. L'enseigne ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )}, c'est votre shopping télé avec des idées, des astuces pour vous et votre intérieur.`}
        />
      </Helmet>
      <StoreLocatorLayout storeLocator={storeLocator} location={location} />
    </Box>
  )
}

StoreLocatorPage.pageType = 'storeLocator'

export default StoreLocatorPage
